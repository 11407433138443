import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';
import { Image } from 'src/containers/Image2';
import { ArticleTeaser } from 'src/components/organisms/ArticleTeaser';

export default function Drawer({ heading, preHeading, image, customData, content, contentNode }) {
  customData = JSON.parse(customData);
  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        <ArticleTeaser image={<Image data={image} role="presentation" />}>
          <h5 className="highlight">{preHeading}</h5>
          <h3>{heading}</h3>
          {content && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        </ArticleTeaser>
      </Section>
    </MDXProvider>
  );
}
